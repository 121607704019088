@import "../../scss/main";


.react-autosuggest__container {
    font-family: "Public Sans", Helvetica, Arial, sans-serif;
    position: relative;

    .react-autosuggest__input {
        border: 2px solid $color-shuttle-grey;
    }

    .react-autosuggest__suggestions-container {
        display: none;
        width: 100%;
        background-color: $color-white;
    }

    .react-autosuggest__suggestions-container--open {
        border: 2px solid $color-shuttle-grey;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        display: block;
        position: absolute;
        z-index: 50;

        transition: visibility 0s linear .1s,
        transform .1s ease-in-out,
        opacity .1s ease-in-out;;
    }

    .react-autosuggest__suggestions-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: $color-ice-blue;
    }

    .react-autosuggest__suggestion {
        color: $color-shuttle-grey;
        cursor: pointer;
        padding: 0.5rem 0.5rem;
    }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
    .suggestions-master-container{
        width: auto;

    }
}

.message-line{
    margin-top: 0.5rem;
    height: 1em;
    line-height: 1em;
    font-family: Public Sans,Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
}
