
.one-question-answer{
    margin: 1em 0;

    .question-text{
        font-weight: bold;
    }

    .answer-container{
        display: flex;
        justify-content: space-between;
        list-style: none;
    }
}
