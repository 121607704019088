
.question-answers-container{
    margin-top: 2rem;

    @media screen and (max-width: 480px) {
        margin-bottom: 2rem;
    }

    .link-button:first-child{
        padding-left: 0;
    }
}
