.residential-address {
    .text-link {
        color: #1f497d;
        text-decoration: underline;
        text-underline-position: under;
    }

    .lighter-text {
        font-weight: 400;
    }

    .manual-mode-text {
        margin-top: 0;
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
        padding: 0;
    }

    @media screen and (min-width: 650px) {
        width: 700px;

        .address-label {
            font-weight: bold;
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            justify-content: space-between;

            label {
                width: 400px;
            }
        }
    }
}

.text-link {
  color: #1f497d;
  text-decoration: underline;
  text-underline-position: under;
}

.error {
  display: block;
  color: red;
}

.show {
  display: block;
}

.hide {
  display: none;
}

label.covid-label{
  font-weight: 500;
  margin-bottom: 0;
}

label.helper{
  font-size: 0.875rem;
  line-height: 1.4;
  color: rgb(100, 105, 116);
  font-weight: 300;
  letter-spacing: normal;
  margin: 0;
}

.covid-address{
  width: inherit;
}

.covid-input[type*=text], select.covid-input{
  max-width: inherit;
}