@import '../../scss/main';

.cards {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: 1px solid $color-border-grey;
    box-shadow: 0 0 4px 2px $color-border-grey;
    margin: 5px;

    &:focus {
        outline-color: $color-info-blue;
    }

    &:hover {
        background-color: $color-info-blue;

        .cards {
            &-title__card {
                background-color: $color-info-blue;
                color: $color-white;
            }

            &-arrow {
                transform: translateX(14px);

                svg {
                    fill: $color-white;
                }

            }

            &-arrow__text {
                background-color: $color-info-blue;
                color: $color-white;
            }
        }
    }

    .lcBLtm {
        margin-bottom: 0;
        box-shadow: none;
        background-color: transparent;
    }

    .cards-image {
        height: auto;
        width: 100%;

        &__image {
            margin-left: auto;
            margin-right: auto;
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .cards-title {
        height: 100%;

        .H6-stkh7p-5 {
            font-size: 18px;
        }

        &__card {
            border-radius: 0;
            height: 100%;
            display: flex;
            flex-flow: column;
        }
    }

    .cards-arrow {
        transition: transform 0.5s ease-in-out;
        font-size: x-large;
        margin-bottom: 10px;
        color: $color-info-blue;
        margin-top: 5px;
        padding-left: 24px;
        &__text {
            font-size: 16px;
            padding-right: 10px;
            font-weight: 500;

        }
    }

}
