.cpd-form{
  max-width: 800px;
}

.flex-right {
  float: right;
}

label {
   font-weight: 700;
}

@media screen and (max-width: 480px) {
  .action_btn .button {
    margin-bottom: 1rem;
  }

  .input-max-width-60 {
    max-width: 60%;
  }
}

.charitable {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.reg-assistance {
  margin-top: -1.5rem;
}

.page-sub-section {
  padding-top: 1rem;
}

.page-nav {
  padding-top: 15px;
  padding-bottom: 5px;

    .button-back {
        text-decoration: underline;
        text-underline-position: under;
        font-size: 16px;
        display: flex;
        align-items: center;

         img {
             margin-right: 10px;
             transform: rotate(180deg);
             width: 10px;
         }
    }
}

.label-desc {
  margin-top: -1.5rem;
  margin-bottom: 1rem;
}

.details {
  .form__item {
    margin: 1rem 0;
  }
}

.error-list {
    padding-left: 0;
}

.cpd-radio-group{
  span[class*="RadioCheckboxLegendLabel-"]{
    font-size: 1rem;
    font-weight: 500;
  }
}

.cpd-phone-input{
  /* Don't know where margin-top: 3.5rem is coming from. Override it with 2rem.
     Inspect element shows ".uGVIN + .field-item, .uGVIN + .checkbox-item, .uGVIN + *" 
     but searching within this file and App.scss reveals nothing. */
  margin-top: 2rem !important;
}

.cpd-declaration-heading{
  margin-bottom: 2rem;
  margin-top: 2rem;
}

div.checkbox-item[class*=Item-] {
  margin-top: 1.5rem;
}

.cpd-header-subtitle {
  margin-top: 2rem;
}