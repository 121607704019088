body {
    font-family: Public Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.form-title {
  font-family: "Public Sans", Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 700;
  margin: 20px 0;
}

.form__actions.btn-section{
  margin-bottom: 0;
  margin-top: 50px;
  max-width: 100%;
}

@media screen and (max-width: 480px) {
  .form__actions.btn-section{
      display: flex;
      flex-direction: column;

      .button{
        max-width: 100%;
      }
  }
}
