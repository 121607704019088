@media screen and (max-width: 760px) {
  .btn-section .button:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.btn-section {
  margin-top: 8rem;
}

.show_element {
  visibility: visible;
}

.hide_element {
  visibility: collapse;
  display: none;
}

.form-certificates{
  .form-title {
    margin-bottom: 0;
  }
}
