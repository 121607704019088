.generic-error {

    > .container {
      margin-bottom: 50px;
    }

    &__link {
        margin-top: 30px;
    }
}
