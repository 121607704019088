@import '../../../scss/main';

.results-page {
    padding-top: 10px;
}

.lighter-text {
  font-weight: normal;
}

.stronger-text {
  font-weight: bold;
}

.form-submitted {
  padding-left: 4rem;
}

.subscribe-success {
  display: flex;
  font-size: large;
  font-weight: bold;
  align-items: center;

  &:before {
    content: url('../../../assets/submit-status.svg') " ";
    display: inline-block;
    margin-right: 1rem;
    margin-top: .5rem;
  }
}

.page-section {
  padding-top: 4rem;
  padding-bottom: 4rem;

  hr {
    border: 1px solid #C4D0D6;
  }
}

@media screen and (min-width: 760px) {
  .button-container {
    display: inline-flex;
  }
}

.btn-spacer {
  margin-right: 1rem;
  margin-top: 1rem;
}

.actions-section {
  display: block;
  width: 100%;
}

:host {
  flex: 1 1 auto;
}

section {
  padding: .5rem 0;
  @include media-above('sm') {
    padding: 2.5rem 0;
  }

  h2, h3 {
    margin-top: 0;
  }
}

.results-summary {
  background-color: $white;
  @include media-above('sm') {
    padding-bottom: 1rem;
  }

  h1 {
    font-size: 2rem;
    @include media-above('sm') {
      font-size: 3rem;
    }
    margin-bottom: 3rem;
    font-weight: 800;
  }

  &__saving {
    color: $success-green;
  }
}

.product-categories {
  padding: 0;
  list-style: none;
  margin-top: 3rem;
}

.widget-sharing {
  margin-top: 1.5rem;
}

.category-container {
  margin: 1em 0em;

  .Collapsible {
    box-shadow: 0 0 0 0.125rem darken($ice-blue, 10%);
    border-radius: $border-radius;

    .category__right-side {
        display: flex;
    }

    .category__arrow {
        display: flex;
        transition: transform 200ms ease-in-out;
    }

    .is-closed {
      .category__arrow {
        transform: rotate(90deg);
      }
    }

    .is-open {
      .category__arrow {
        transform: rotate(-90deg);
      }
    }
  }

}
@media screen and (max-width: 500px) {
  .service-container {
    padding: 0;
  }
}

.service-container {
  border-top: 2px solid $border-grey;
  padding-left: 0;
}

.product-container {
  padding: 26px 40px;
  word-break: break-word;
}


.category-expanded, .category-collapsed, .category {
  background-color: $category-background-color;
  padding: 2rem 4rem 2rem 2rem;
  @include media-above('sm') {
    padding: 3rem 5rem 3rem 3rem;
  }
  position: relative;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  @include media-below('sm') {
    flex-direction: column;
    align-items: flex-start;
  }
  text-decoration: none;
  //box-shadow: 0 0 0 0.125rem darken($ice-blue, 10%);
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0 0 0.125rem $info-blue;
    cursor: pointer;
  }

  &__content {
    display: flex;
  }

  &__description {
    color: $service-grey;
    font-style: italic;
  }

  &__title {
    color: $info-blue;
    margin: 0;
    text-decoration: underline;
    font-weight: 600;
  }

  &__total {
    font-size: 1.2rem;
    color: $service-grey;
    font-weight: 500;
    padding-right: 25px;
    display: flex;
    justify-content: flex-end;
    @include media-below('sm') {
      margin-top: 1rem;
    }
  }

  &:hover {
    box-shadow: 0 0 0 0.125rem $info-blue;
  }

  .blue-pill {
    background-color: $info-blue;
    border-radius: 8px;
    color: $white;
    font-size: .8rem;
    font-weight: bold;
    padding: .3rem .8rem;
    margin-left: .8rem;
    height: 1.8rem;
    white-space: nowrap;
    text-align: center;
  }
}

.grid__container.invisible {
    visibility: hidden;
}

.grid__container ul {
  list-style-type: none;
}

%chevron {
  content: url('../../../assets/icons/chevron.svg');
  position: absolute;
  right: 2rem;
  top: calc(50% - 0.75rem);
}

.product {
  background-color: #fff;
  display: flex;

  &__content {
    flex: 1 1 auto;
    flex-direction: column;

    .new-item {
      background-color: $info-blue;
      border-radius: 8px;
      color: $white;
      font-size: .7rem;
      font-weight: bold;
      padding: .2rem .6rem;
      margin-top: 15px;
      height: 1.4rem;
      display: inline-block;
    }
  }

  &__initiative_name {
    color: $info-blue;
    margin: 0;
    text-align: left;
    text-decoration: underline;
    font-weight: 900;
    display: flex;

    a:focus, a:active {
        outline-color: $color-info-blue;
    }
  }

  &__description {
    color: $service-grey;
    word-wrap: break-word;
  }

  &__eligibility {
    color: $service-grey;
    padding: 0;
    word-wrap: break-word;
    ul {
      list-style-type: disc;
    }
  }

  &__further_info {
    text-align: right;

    &_link {
        color: $info-blue;
        font-weight: 300;
        text-decoration: underline;

        &:after {
            content: url('../../../assets/icons/chevron.svg');
            vertical-align: top;
            margin-left: 20px;
        }

        &:focus, &:active {
            outline-color: $color-info-blue;
        }
    }
  }

  &__provider {
    color: $service-grey;
    text-align: right;
    padding-bottom: .5em;
  }

  a {
    color: $info-blue;

    &:visited {
      color: $info-blue;
    }
  }
}

.link-desc {
  display: none;
}

.subscribe-container {
  padding: 3rem;
  background-color: #F2F7FA;
}

hr.spacer {
  margin-top: 0;
  border: 0;
}

@media screen and (max-width: 480px) {
  .subscribe-container {
    padding: 1rem 2.5rem 3rem 2.5rem;
  }
  
  .hide-btn-text {
    display: none;
  }

  .product {
    margin-right: 0;

    &__initiative_name {
      font-weight: 500;
      font-size: medium;
    }

    &__eligibility {
      max-width: 100%;
    }

    &__provider {
      text-align: left;
    }

    &__footer {
      padding-top: 1em;
    }

    &__further_info {
      text-align: left;
    }
  }

  .category {

    &__title {
      font-size: large;
    }

    &__total {
      font-size: medium;
    }
  }
}

.send-email {
    .form__input-group {
      display: flex;

      & .form__item {
        flex-grow: 1;

        &:first-child {
          & input {
            max-width: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      & .form__actions {
        flex-shrink: 0;
        align-items: center;

        & .button {
          min-width: 0;

          &:before {
            content: url('../../../assets/email.svg') " ";
            padding-right: 1rem;
            position: relative;
            top: .2rem;
          }
        }

        &:last-child {
          & .button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
    }
}

.page-sub-section.send-email{
  background-color: #F2F7FA;
  padding: 2rem;
}

.page-sub-section-nobg.send-email{
    background-color: white;
    //padding: 2rem;
}

@media print {
  .category-expanded, .category-collapsed, .category {
    background-color: $category-background-color;
    padding: 0;
    @include media-above('sm') {
      padding: 3rem 5rem 3rem 3rem;
    }
    position: relative;
    border-radius: 0;
    display: flex;
    align-items: center;
    @include media-below('sm') {
      flex-direction: column;
      align-items: flex-start;
    }
    text-decoration: none;
    //box-shadow: 0 0 0 0.125rem darken($ice-blue, 10%);
    justify-content: space-between;

    &__content {
      display: flex;
    }

    &__description {
      color: $service-grey;
      font-style: italic;
    }

    &__title {
      color: $service-grey;
      margin: 0;
      text-decoration: none;
      font-weight: 600;
    }

    &__total {
      display: none;
    }

    .blue-pill {
      background-color: $info-blue;
      border-radius: 8px;
      color: $white;
      font-size: .8rem;
      font-weight: bold;
      padding: .3rem .8rem;
      margin-left: .8rem;
      height: 1.8rem;
    }
  }

  .product {
    background-color: #fff;
    padding: 0em 2rem;
    margin-bottom: 1rem;
    margin-right: 1em;
    position: relative;
    border-radius: 0;
    display: flex;
    text-decoration: none;

    &__content {
      flex: 1 1 auto;
      flex-direction: column;
      padding-top: 1.5em;

      .new-item {
        background-color: $info-blue;
        border-radius: 8px;
        color: $white;
        font-size: .7rem;
        font-weight: bold;
        padding: .2rem .6rem;
        margin-left: 1rem;
        height: 1.4rem;
      }
    }

    &__initiative_name {
      color: $service-grey;
      text-decoration: none;
    }

    &__description {
      color: $service-grey;
      padding: 1.5em 0em;
      word-wrap: break-word;
    }

    &__further_info {
      text-align: left;
      color: $service-grey;
      text-decoration: none;

      &_link {
        display: none;
      }
    }

    &__provider {
      color: $service-grey;
    }

    a {
      color: $service-grey;
      text-decoration: none;

      &:visited {
        color: $service-grey;
        text-decoration: none;
      }
    }
  }

  .category-container {
    margin: 1em 0em;
    border-bottom: 1px solid $border-grey;

    .Collapsible {
      box-shadow: none;
      .is-closed, .is-open {
        .category:after {
          content: none;
        }
      }
    }
  }

  .subscribe-success, .success-desc,
  .subscribe-container {
    display: none;
  }

  .link-desc {
    display: block;
    text-decoration: underline $service-grey;
  }

  hr.spacer {
    margin-top: 2rem;
    border: 1px solid $border-grey;
  }
}

.summary-signup
{
  margin-top: 3em;
  margin-bottom: 3em;
  padding: 2em;
  background-color: $category-background-color;
}

.other-assistance-finders {
    margin-top: 2rem;
    margin-bottom: 2rem;

    h3 {
        margin-bottom: 0;
    }

    p {
        margin-top: 0;
        margin-bottom: 1.5rem;
    }
}

.daf-line-spacing{
    border: 1px solid #DEE3E5;
    border-radius: 0.3em;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
