@import "../../../scss/main";

.call-to-action {
    border-top: 2px solid $color-border-grey;
    padding: 26px 40px;

    &__title {
        font-weight: bold;
    }

    &__text {
        margin-top: 10px;

        li {
            list-style-type: disc;
        }
    }

    &__new-para {
        margin-top: 15px;
    }

    &__link {

        a {
            color: $color-info-blue;

            &:focus {
                outline-color: $color-info-blue;
            }

            &:after {
                content: url("data:image/svg+xml;charset=utf-8,%3Csvg height='12' width='12' fill='%232e5299' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0v12h12V0H0zm10.9 10.9H1.1V1.1h9.8v9.8z'/%3E%3Cpath d='M4.4 2.7v1.1h3L2.3 8.9l.8.8 5.1-5.1v3h1.1V2.7H4.4z'/%3E%3C/svg%3E");
                display: inline-block;
                width: 12px;
                height: 12px;
                margin-left: 8px;
                vertical-align: text-top;
            }
        }
    }
}
