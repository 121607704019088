
$color-service-red: #c60c30;
$color-service-red-border: #a71930;
$color-service-red-hover: #e80229;
$color-service-blue: #002664;
$color-service-grey: #242934;
$color-info-blue: #2e5299;
$color-white: #fff;
$color-ice-blue: #f4f7f9;
$color-tile-grey: #ecf1f3;
$color-border-grey: #dee3e5;
$color-shuttle-grey: #646974;
$color-black: #000;
$color-dark-red: #990009;
$color-success-green: #00a908;
$color-error-red: #b81237;
$color-warning-orange: #dc5800;
$color-info-blue-light: #eaedf4;
$color-success-green-light: #e5f6e6;
$color-error-red-light: #f4dbe1;
$color-warning-orange-light: #fcefe6;
$color-button-grey: #f9fdff;
$color-button-grey-border: #c4d0d6;
$color-button-grey-border-hover: #deeaf0;
$color-button-grey-bg-active: #e0e4e6;
$color-button-grey-border-active: #929fa4;
$color-button-blue-border: #274a8f;
$color-button-blue-hover: #5272b1;
$color-button-blue-active: lighten($color-button-blue-hover, 30%);
$color-debug-background: plum;
$color-debug-border: darkmagenta;
$color-empty-grey: #C4C4C4;
$color-fun-blue: #14387f;

$color-background-list: #f9f9f9;
