.flex-right {
  float: right;
}

@media screen and (min-width: 480px) {
  .input-max-width-60 {
    max-width: 60%;
  }
}

.add-child-div {
  padding-top: 1rem;
  display: inline-flex;
}

@media screen and (max-width: 480px) {

  .action_btn .button {
    margin-bottom: 1rem;
  }
}
