.category {

    &-content {
        display: block;

        &__title {
            display: inline-block;
            color: #2e5299;
            margin: 0;
            text-decoration: underline;
            font-weight: 600;
        }

        &__new-services {
            text-transform: uppercase;
        }

        &__service-ends-soon {
            font-weight: 500;
            margin-left: 15px;
            text-transform: uppercase;
        }
    }
}
