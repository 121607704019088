@import '../../scss/main';

#quickFeedHolder {
    display: block;
    width: auto;
    position: fixed;
    bottom: 0;
    height: $feedback-holder-size;
    z-index: 1000;
    margin-top: auto;

    @media screen and (min-width: 500px) {
        width: $feedback-holder-size;
        right: 0;
        top: 25%;
        bottom: initial;

        h1 {
            padding-top: 1rem;
            writing-mode: vertical-lr;
            vertical-align: middle;
            font-weight: bold;
        }
    }

    @media print {
        display: none;
    }

    #onegov-feedback-modal {

        @media screen and (min-width: 500px) {
            height: 100% !important;
        }

        #ogf-navigation.ogf-static {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            padding: 0 6px 10px 6px!important;

            @media screen and (min-width: 500px) {
                border-bottom-left-radius: 10px !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                margin: 0 !important;
                padding: 0 6px 4px 6px !important;
            }

            button {
                margin: 0 !important;
            }

            #ogf-feedback-label {
                display: inline-block !important;
                margin: 0 5px 0 0 !important;
                pointer-events: none;

                @media screen and (min-width: 500px) {
                    transform: rotate(180deg) !important;
                    margin: 10px 0 !important;
                    display: block !important;
                }
            }

            .ogffont-feedback {
                top: 0!important;
            }
        }
    }
}

.form-feedback {
  width: auto;
}

#onegov-feedback-modal #ogf-navigation.ogf-static #ogf-feedback-label h1 {
  font-family: Public Sans, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
}
