///* You can add global styles to this file, and also import other style files */
@import './helpers/variables';
@import './helpers/colours';
@import './mixins/typography';
@import './mixins/breakpoints';
//@import "node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
//@import '~bootstrap/scss/bootstrap-grid';

.servicensw-canvas, body {
    flex-shrink: 0;
}

//body {
//    font-family: Public Sans, Helvetica, Arial, sans-serif;
//}

//
//html,
//body {
//  font-family: "Public Sans", Helvetica, Arial, sans-serif;
//  color: $service-grey;
//  background: $ice-blue;
//  margin: 0;
//  padding: 0;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
//  height: 100%;
//  width: 100%;
//  min-height: 100vh;
//  font-size: 13px;
//  @include media-above('sm') {
//    font-size: 16px;
//  }
//}
//
//div#body {
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  overflow: scroll;
//  -webkit-overflow-scrolling: touch;
//}
//
//.min-height {
//  min-height: 80vh;
//}
//
//.grid__container {
//  @include media-below('sm') {
//    padding: 1rem;
//  }
//}
//
//a {
//  color: $info-blue;
//
//  &:hover {
//    color: $service-red;
//  }
//
//  &:focus {
//    outline: 2px solid $info-blue;
//  }
//
//  &[href*="//"]:not([href*="service.nsw.gov.au"]):after {
//    content: '';
//    display: inline-block;
//    width: 0.8em;
//    height: 0.8em;
//    margin: 0 0.15em;
//    background: url('/assets/icons/external.svg') no-repeat center;
//    background-size: contain;
//  }
//}
//
//h1 {
//  @include type-style(2.5rem, 1.25);
//}
//
//h2 {
//  @include type-style(1.75rem, 1.285);
//}
//
//h3 {
//  @include type-style(1.5rem, 1.333);
//}
//
//h4 {
//  @include type-style(1.312rem, 1.2381);
//}
//
//h5 {
//  @include type-style(1.125rem, 1.333);
//}
//
//h6 {
//  @include type-style(1rem, 1.312);
//}
//
//p {
//  font-size: 16px;
//  line-height: 1.5;
//}
//
//strong {
//  font-weight: 600;
//}
//
//.mat-tooltip {
//  font-size: 12px;
//  font-family:  $font-primary;
//}
//
//.text-link {
//  color: #1f497d;
//  text-decoration: underline;
//}
//
//.mat-tooltip.tooltip
//{
//  @include media-above('sm') {
//    font-size: 13px; // current default is 10px :-/
//  }
//  background-color: #212121;
//  max-width: 350px;
//}
//
//.mat-stepper-horizontal, .mat-stepper-vertical {
//  font-family:  $font-primary;
//}
//
//.mat-card {
//  padding: 8px !important;
//  background: $light-blue-color;
//  font-family:  $font-primary;
//}
//
//mat-card {
//  transition: none !important;
//  box-shadow: none !important;
//}
