@import '../../../scss/main';

.step-wizard {
    &__title {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 2rem;
    }

    &__nav {
        display: flex;
        color: $color-service-blue;

        &--hidden {
            display: none;
        }
    }

    &__tab {
        font-size: 1rem;
        font-weight: bold;
        width: 33%;
        padding: 0 0 1em 0;
        margin-right: .3em;
        border-bottom: .4em solid $color-service-blue;
        opacity: 0.1;

        &--active {
            opacity: 1;
        }
    }
}
