/* You can add shared variables and mixins to this file, then import into any components that require it */

$white: #FFFFFF;
$black: #000000;
$category-background-color: #F4F7F9;

// Brand colours from Service NSW GEL
$service-red: #C60C30;
$service-navy: #002664;
$service-grey: #242934;

// UI colours from Service NSW GEL
$info-blue: #2E5299;

// Background colours from Service NSW GEL
$ice-blue: #F4F7F9;
$tile-grey: #ECF1F3;
$border-grey: #DEE3E5;
$shuttle-grey: #646974;

// Button colours from Service NSW GEL
$dark-red: #990009;

// Notification colours from Service NSW GEL
$info-blue-light: #EAEDF4;
$success-green: #00A908;
$success-green-light: #E5F6E6;
$error-red: #B81237;
$error-red-light: #F4DBE1;
$warning-orange: #DC5800;
$warning-orange-light: #FCEFE6;

// Wrapper sizing
$container-width: 90%;
$max-container-width: 75em;

$border-radius: .3em;

$font-primary: "Public Sans", Helvetica, Arial, sans-serif;

$light-blue-color: #F3F7F9;

$feedback-holder-size: 58px;
