@import '../../scss/main';

.App {
    &-logo {
        height: 40vmin;
    }

    &-link {
        color: #09d3ac;
    }

    ul.autocomplete {
        padding-inline-start: 0;
        padding: 2px;

        .autocomplete__option {
            padding-left: 2rem;
            font-size: 1.3125rem;
            border: 2px solid $color-white;
            color: $color-service-grey;

            &[aria-selected="true"] {
                border-style: solid;
                border-color: $color-service-blue;
                //background-color: $color-button-blue-hover;
                //color: $color-white;
            }
        }
    }

    .notice .button--link.button--external {
        background-image: none;
    }

    .page {
        margin-bottom: 50px;
    }

    [class*="FormFieldset-"] {
        margin-top: 2em;
    }

    [class*="RadioCheckboxLegendLabel-"] {
        font-size: 1.5em;
        font-weight: bold;
    }

    .checkbox-item [class*="FormLabel-"],
    .radio-item [class*="FormLabel-"]{
        font-weight: normal;
    }
}

.pad-left-2 {
    padding-left: 2rem;
}

.pad-top-1 {
    padding-top: 1rem;
}

.new-section {
    //margin-top: 5rem;
}

.form-segment {
    padding-bottom: 2.5em;
}

.hidden {
    display: none;
}

.text-underline {
    color: #1f497d;
    text-decoration: underline;
    text-underline-position: under;
    font-weight: 500;
}
