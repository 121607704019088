@import '../../../scss/main';

.results-page {
    padding-top: 10px;
}

.qualtrics-sections {
    background-color: #002664;
    color: $color-white;
    padding: 2rem 0;

    .container {
        //display: flex;

        &:before {
            content: url('../../../assets/icons/speech-bubbles.svg') " ";
            display: block;
        }
    }

    &__feedback {
        .feedback-title {
            font-size: 2em;
            font-weight: bold;
            margin: 10px 0;
        }

        .feedback-text {
            margin-bottom: 40px;
        }
    }
}

.resubscribe-button {
  outline: none;
  padding: 0;
  border: 0;
  box-sizing: unset;
  background-color: transparent;
  font-size: inherit;
  color: $color-info-blue;
  cursor: pointer;
  text-decoration: underline;
  font-family: inherit;
}

.covid-summary-signup {
  padding: 2em;
  background-color: $category-background-color;
}
.loader-div {
  .DimmerContainer-okoliw-0 {
    .LoaderContainer-sc-1hi7djn-0 {
      margin: 15px;
      white-space: pre-line;
    }
  }
}

.services-text {
  border-top: 2px solid $color-border-grey;
  font-family: Public Sans,Helvetica,Arial,sans-serif;
  font-size: 18px;
  font-weight: bold;
  background-color: $color-white;
  padding: 2rem 4rem 2rem 2rem;

  @include media-above('sm') {
    padding: 3rem 5rem 3rem 3rem;
  }

    &-url {
        color: $color-info-blue;

        &:focus {
            outline-color: $color-info-blue;
        }
    }
}
.services-box{
  font-family: Public Sans,Helvetica,Arial,sans-serif;
  font-size: 18px;
  display: flex;
  font-weight: bold;
  background-color: $category-background-color;
  padding: 2rem 4rem 2rem 2rem;

    @include media-below('xs') {
        padding: 2rem 2rem 2rem 2rem;
    }

  @include media-above('sm') {
    padding: 2rem 5rem 2rem 3rem;

  }
  position: relative;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  @include media-below('sm') {
    flex-direction: column;
    align-items: flex-start;

  }
    text-decoration: none;
    position: relative;
    //box-shadow: 0 0 0 0.125rem darken($ice-blue, 10%);
    justify-content: space-between;

    &:hover {
        box-shadow: 0 0 0 0.125rem $info-blue;
        cursor: pointer;
    }

    .normal-text {
        font-size: 1rem;
        font-weight: 100;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #242934;
    }

    .form__error{
        font: message-box;
        line-height: inherit;
    }

    .notice--error{
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        line-height: 1.5;
        font-weight: normal;

    }
}

.services-box--container {
    @extend .services-box;
    display: flex;
    flex-direction: column;
    padding: 0;
}


.link-button {
    background: none !important;
    border: none;
    padding-left: 1rem;
    text-decoration: underline;
    cursor: pointer;

    // Zepplin properties
    height: 24px;
    font-family: Public Sans,Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    color: #2e5299;
    align-self: flex-end;
}

.autosuggest-button-container-1 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    align-items: baseline;
    margin: 0 -14px;

    @media only screen and (max-width: 400px){
      flex-wrap: wrap;
    }

    .flex-spacing {
        margin: 5px 14px;
    }

    .item {

        width: auto;
        flex-grow: 1;
        .form__input-group {
            margin: 0;
        }

    }

}

.suggestions-master-container{

    max-width: 500px;

    .form__item{
        margin: 0;
    }

    @include media-below(sm){
        #toggle-button{
         margin-left: auto;
        }
    }

    .button--link{
      font-weight: 400;
    }
    .label-button-container{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap-reverse;

        @include media-below(xs){
            justify-content: flex-start;
        }

    }


    .form__item > label{
        font-size: 1rem;
        font-weight: normal;
        width: auto;
        //margin-bottom:0.25rem
    }

    .autosuggest-button-container{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;

        margin: 0 -0.5rem;

        @include media-below(xs){
            margin: -0.5rem -0.5rem;
        }

        .autosuggest-button-container-item{
            margin: 0 0.5rem;

            @include media-below(xs){
                margin: 0.5rem 0.5rem;
            }

            .button{
                min-width: auto;
            }
        }
    }
}

.summary-container{
    @include media-below(sm){
        .message-line{
           height: auto;
       }
    }
}

#box-error{
    width: 60%;
    @media screen and (max-width: 500px) {
        width: 100%;
    }

 }

.summary-header__ref-no{
    font-weight: 500;
}
.ref-color{
   color: rgb(46, 82, 153);
    font-size: 16px;
}

.line-spacing{
    border: 1px solid #DEE3E5;
    border-radius: 0.3em ;
    margin-top: 50px ;
    margin-bottom: 50px ;
}

.general-information {
    margin-top: 50px;

    &__title {
        letter-spacing: 0;
        max-width: 55.625rem;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.33333;
        margin-bottom: 8px;
    }

    &__content {
        display: flex;
        justify-content: space-between;

        @include media-below(sm){
            flex-direction: column;
            width: auto;
        }

        @include media-above(sm) {
            .cards {
                width: calc((100% / 3) - 18px)
            }
        }
    }
}
