
@import '../../../scss/main';

.email-error {
    margin-top: 0.5rem !important;

}
.line-spacing{
    border: 1px solid #DEE3E5;
    border-radius: 0.3em ;
    margin-top: 50px ;
    margin-bottom: 50px ;
}


